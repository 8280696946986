/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useScrollDirection } from '../../hooks';
import routeNames from '../../routes/routeNames';
import { BiCheckbox, BiCheckboxSquare } from "react-icons/bi";
import { GoHome } from 'react-icons/go';
import { IoSchoolOutline } from 'react-icons/io5';
import { HiOutlineUsers } from 'react-icons/hi';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { TbAward } from 'react-icons/tb';
import { BiSupport } from 'react-icons/bi';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

import './styles.scss';
// import { admissionResultExistsApi } from '../../apis';
import DeferredImage from '../DeferredImage';

const Header = ({ t }) => {
   const scrollDirection = useScrollDirection();
   const { pathname } = useLocation();
   // const [admissionResultExists, setAdmissionResultExists] = useState(false);
   const admissionResultExists = true;
   // useEffect(() => {
   //    admissionResultExistsApi((res) => {
   //       if (res?.data?.exists) {
   //          setAdmissionResultExists(true);
   //       } else {
   //          setAdmissionResultExists(false);
   //       };
   //    });
   // }, []);


   return (
      <div className='header'>
         <nav className="navbar navbar-1 scroll navbar-expand-md">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
               <ul className="navbar-nav">
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.virtualLearning) ? 'active' : ''}`} href={routeNames.general.virtualLearning}>{t("header.virtualLearning")}</a>
                  </li>
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.cambridgeExamUpdates) ? 'active' : ''}`} href={routeNames.general.cambridgeExamUpdates}>{t("header.cambridgeExamUpdates")}</a>
                  </li>
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.jobOpportunities) ? 'active' : ''}`} href={routeNames.general.jobOpportunities}>{t("header.jobOpportunities")}</a>
                  </li>
                  {/* News & Events */}
                  <li className="nav-item dropdown">
                     <a className={`${pathname?.includes(routeNames.general.newsEvents.newsArchive) ? 'active' : ''}`} href={routeNames.general.newsEvents.newsArchive}>
                        {t("header.newsEvents")}
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className="project newsEvents">
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.newsEvents.newsArchive) ? 'active' : ''}`} href={routeNames.general.newsEvents.newsArchive}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.newsArchive")}
                           </a>
                        </li>
                     </ul>
                  </li>

                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.gallery) ? 'active' : ''}`} href={routeNames.general.gallery}>{"Gallery"}</a>
                  </li>
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.downloads) ? 'active' : ''}`} href={routeNames.general.downloads}>{t("header.downloads")}</a>
                  </li>
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.pisesianHangouts) ? 'active' : ''}`} href={routeNames.general.pisesianHangouts}>{t("header.pisesianHangouts")}</a>
                  </li>
                  {/* <li className="nav-item">
                     <a className="nav-link" target="_blank" rel="noreferrer" href={routeNames.general.pnsi}>{t("header.PSNI")}</a>
                  </li> */}
                  {/* Portal */}
                  <li className="nav-item dropdown">
                     <a href="#">
                        {t("header.portal")}
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className="project portal">
                        <li className="drop-item">
                           <a href={routeNames.general.login}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              Admin Portal
                           </a>
                        </li>
                        {/* <li className="drop-item">
                           <a target="_blank" rel="noreferrer" href={routeNames.general.portal.staffPortal}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.staffPortal")}
                           </a>
                        </li> */}
                        {/* s */}
                        {/* <li className="drop-item">
                           <a target="_blank" rel="noreferrer" href={routeNames.general.portal.studentPortal}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.studentPortal")}
                           </a>
                     </li> */}
                        <li className="drop-item">
                           <a target="_blank" rel="noreferrer" href={routeNames.general.portal.msTeams}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.msTeams")}
                           </a>
                        </li>
                     </ul>
                  </li>
               </ul>
            </div>
         </nav>

         <div className={`scroll ${scrollDirection === "down" ? "hidden" : "visible"}`}>
            <nav className='navbar-2'>
               <div className='container'>
                  <a className="navbar-brand" href="/">
                     <DeferredImage src='/full-logo.jpeg' width='197px' height="60px" alt="logo" />
                  </a>
                  <div className="d-flex d-lg-none menu-icon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                     <i className='bx bx-menu-alt-right bx-md'></i>
                  </div>
                  <ul className="nav-bar">
                     <li className="nav-item">
                        <a className={`${pathname?.includes(routeNames.general.landing) ? 'active' : ''}`} href={routeNames.general.landing}>
                           <GoHome className='i' />
                           {t("header.home")}
                        </a>
                     </li>

                     {/* About Us */}
                     <li className="nav-item dropdown">
                        <a
                           className={`${(pathname?.includes(routeNames.general.aboutUs.missionAndVision) || pathname?.includes(routeNames.general.aboutUs.learningGoals) || pathname?.includes(routeNames.general.aboutUs.principalsMessage)) ? 'active' : ''}`}
                           href={routeNames.general.aboutUs.missionAndVision}
                        >
                           <HiOutlineUsers className='i' />
                           {t("header.aboutUs")}
                           <FiChevronDown className='arrow' />
                        </a>
                        <ul className="project">
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.aboutUs.missionAndVision) ? 'active' : ''}`} href={routeNames.general.aboutUs.missionAndVision}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.missionAndVision")}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.aboutUs.learningGoals) ? 'active' : ''}`} href={routeNames.general.aboutUs.learningGoals}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.learningGoals")}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.aboutUs.principalsMessage) ? 'active' : ''}`} href={routeNames.general.aboutUs.principalsMessage}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.principalsMessage")}
                              </a>
                           </li>
                        </ul>
                     </li>

                     {/* School */}
                     <li className="nav-item dropdown">
                        <a
                           className={`${(pathname?.includes(routeNames.general.school.lifeAtPises) || pathname?.includes(routeNames.general.school.achievements.examResults) || pathname?.includes(routeNames.general.school.achievements.otherAchievements) || pathname?.includes(routeNames.general.school.achievements.scienceAndTechnology) || pathname?.includes(routeNames.general.school.hygieneAndMedical) || pathname?.includes(routeNames.general.school.pisesFacilities) || pathname?.includes(routeNames.general.school.schoolTiming) || pathname?.includes(routeNames.general.school.schoolUniform)) ? 'active' : ''}`}
                           href={routeNames.general.school.lifeAtPises}
                        >
                           <IoSchoolOutline className='i' />
                           {t("header.school")}
                           <FiChevronDown className='arrow' />
                        </a>
                        <ul className="project school">
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.school.lifeAtPises) ? 'active' : ''}`} href={routeNames.general.school.lifeAtPises}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.lifeAtPises")}
                              </a>
                           </li>
                           <li className="drop-item sub-dropdown">
                              <a
                                 className={`${(pathname?.includes(routeNames.general.school.achievements.examResults) || pathname?.includes(routeNames.general.school.achievements.otherAchievements) || pathname?.includes(routeNames.general.school.achievements.scienceAndTechnology || pathname?.includes(routeNames.general.school.schoolTiming))) ? 'active' : ''}`}
                                 href={routeNames.general.school.achievements.examResults}
                              >
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.achievements")}
                                 <FiChevronRight className='arrow' />
                              </a>
                              <ul className="sub-project">
                                 <li className="drop-item">
                                    <a className={`${pathname?.includes(routeNames.general.school.achievements.examResults) ? 'active' : ''}`} href={routeNames.general.school.achievements.examResults}>
                                       <BiCheckbox className='icon' />
                                       <BiCheckboxSquare className='icon-active' />
                                       {t("header.examResults")}
                                    </a>
                                 </li>
                                 <li className="drop-item">
                                    <a className={`${pathname?.includes(routeNames.general.school.achievements.scienceAndTechnology) ? 'active' : ''}`} href={routeNames.general.school.achievements.scienceAndTechnology}>
                                       <BiCheckbox className='icon' />
                                       <BiCheckboxSquare className='icon-active' />
                                       {t("header.scienceAndTechnology")}
                                    </a>
                                 </li>
                                 <li className="drop-item">
                                    <a className={`${pathname?.includes(routeNames.general.school.achievements.otherAchievements) ? 'active' : ''}`} href={routeNames.general.school.achievements.otherAchievements}>
                                       <BiCheckbox className='icon' />
                                       <BiCheckboxSquare className='icon-active' />
                                       {t("header.otherAchievements")}
                                    </a>
                                 </li>
                              </ul>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.school.pisesFacilities) ? 'active' : ''}`} href={routeNames.general.school.pisesFacilities}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.pisesFacilities")}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.school.hygieneAndMedical) ? 'active' : ''}`} href={routeNames.general.school.hygieneAndMedical}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.hygieneAndMedical")}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.school.schoolTiming) ? 'active' : ''}`} href={routeNames.general.school.schoolTiming}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {"School Timing"}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.school.schoolUniform) ? 'active' : ''}`} href={routeNames.general.school.schoolUniform}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {"School Uniform"}
                              </a>
                           </li>
                        </ul>
                     </li>

                     {/* Admissions */}
                     <li className="nav-item dropdown">
                        <a className={`${(pathname?.includes(routeNames.general.admissions.admissionNotice) || pathname?.includes(routeNames.general.admissions.feeStructure) || pathname?.includes(routeNames.general.admissions.entryTestGuidelines) || pathname?.includes(routeNames.general.admissions.pisesBankDetails) || pathname?.includes(routeNames.general.admissions.admissionGuidelines)) ? 'active' : ''}`} href={routeNames.general.admissions.admissionNotice}>
                           <AiOutlineUsergroupAdd className='i' />
                           {t("header.admissions")}
                           <FiChevronDown className='arrow' />
                        </a>
                        <ul className={`project admissions ${admissionResultExists ? 'showRes' : ''}`}>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.admissions.admissionGuidelines) ? 'active' : ''}`} href={routeNames.general.admissions.admissionGuidelines}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 Admission Procedure
                              </a>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.admissions.admissionNotice) ? 'active' : ''}`} href={routeNames.general.admissions.admissionNotice}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 Admission Notice
                              </a>
                           </li>

                           {/* {admissionResultExists &&
                              <li className="drop-item">
                                 <a className={`${pathname?.includes(routeNames.general.admissions.admissionResult) ? 'active' : ''}`} href={routeNames.general.admissions.admissionResult}>
                                    <BiCheckbox className='icon' />
                                    <BiCheckboxSquare className='icon-active' />
                                    Admission Result
                                 </a>
                              </li>
                           } */}
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.admissions.feeStructure) ? 'active' : ''}`} href={routeNames.general.admissions.feeStructure}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.feeStructure")}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.admissions.entryTestGuidelines) ? 'active' : ''}`} href={routeNames.general.admissions.entryTestGuidelines}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.entryTestGuidelines")}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.admissions.pisesBankDetails) ? 'active' : ''}`} href={routeNames.general.admissions.pisesBankDetails}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.pisesBankDetails")}
                              </a>
                           </li>
                        </ul>
                     </li>

                     {/* Academics */}
                     <li className="nav-item dropdown">
                        <a
                           className={`${(pathname?.includes(routeNames.general.academics.academicCalendar) || pathname?.includes(routeNames.general.academics.classesOffered) || pathname?.includes(routeNames.general.academics.preSchool) || pathname?.includes(routeNames.general.academics.juniorSchool) || pathname?.includes(routeNames.general.academics.middleSchool) || pathname?.includes(routeNames.general.academics.seniorSchool)) ? 'active' : ''}`}
                           href={routeNames.general.academics.academicCalendar}
                        >
                           <TbAward className='i' />
                           {t("header.academics")}
                           <FiChevronDown className='arrow' />
                        </a>
                        <ul className="project academics">
                           <li className="drop-item sub-dropdown">
                              <a
                                 className={`${pathname?.includes(routeNames.general.academics.academicCalendar) ? 'active' : ''}`}
                                 href={routeNames.general.academics.academicCalendar}
                              >
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.academicCalendar")}
                                 <FiChevronRight className='arrow' />
                              </a>
                              <ul className="sub-project academics">
                                 <li className="drop-item">
                                    <a
                                       className={`${pathname?.includes(routeNames.general.academics.academicCalendar) ? 'active' : ''}`}
                                       href={routeNames.general.academics.academicCalendarTwentyfour}
                                    >
                                       <BiCheckbox className='icon' />
                                       <BiCheckboxSquare className='icon-active' />
                                       {'Academics Calendar\n2024-2025'}
                                    </a>
                                 </li>
                              </ul>
                           </li>
                           <li className="drop-item">
                              <a
                                 className={`${pathname?.includes(routeNames.general.academics.classesOffered) ? 'active' : ''}`}
                                 href={routeNames.general.academics.classesOffered}
                              >
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.classesOffered")}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a
                                 className={`${pathname?.includes(routeNames.general.academics.preSchool) ? 'active' : ''}`}
                                 href={routeNames.general.academics.preSchool}
                              >
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.preSchool")}
                              </a>
                           </li>
                           <li className="drop-item">
                              <a
                                 className={`${pathname?.includes(routeNames.general.academics.juniorSchool) ? 'active' : ''}`}
                                 href={routeNames.general.academics.juniorSchool}
                              >
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.juniorSchool")}
                              </a>
                           </li>

                           <li className="drop-item">
                              <a
                                 className={`${pathname?.includes(routeNames.general.academics.seniorSchool) ? 'active' : ''}`}
                                 href={routeNames.general.academics.seniorSchool}
                              >
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 {t("header.seniorSchool")}
                              </a>
                           </li>
                        </ul>
                     </li>

                     {/* Contact us */}
                     <li className="nav-item">
                        <a className={`${pathname?.includes(routeNames.general.contactUs) ? 'active' : ''}`} href={routeNames.general.contactUs}>
                           <BiSupport className='i' />
                           {t("header.contactUs")}
                        </a>
                     </li>
                  </ul>
               </div>
            </nav>
         </div>

         <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header">
               <h3 className='heading text-white'>PISES</h3>
               <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
               <ul className="nav-bar">
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.general.landing) ? 'active' : ''}`} href={routeNames.general.landing}>
                        <GoHome className='i' />
                        {t("header.home")}
                     </a>
                  </li>

                  {/* About Us */}
                  <li className="nav-item dropdown">
                     <a
                        className={`${(pathname?.includes(routeNames.general.aboutUs.missionAndVision) || pathname?.includes(routeNames.general.aboutUs.learningGoals) || pathname?.includes(routeNames.general.aboutUs.principalsMessage)) ? 'active' : ''}`}
                     // href={routeNames.general.aboutUs.missionAndVision}
                     >
                        <HiOutlineUsers className='i' />
                        {t("header.aboutUs")}
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className="project">
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.aboutUs.missionAndVision) ? 'active' : ''}`} href={routeNames.general.aboutUs.missionAndVision}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.missionAndVision")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.aboutUs.learningGoals) ? 'active' : ''}`} href={routeNames.general.aboutUs.learningGoals}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.learningGoals")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.aboutUs.principalsMessage) ? 'active' : ''}`} href={routeNames.general.aboutUs.principalsMessage}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.principalsMessage")}
                           </a>
                        </li>
                     </ul>
                  </li>

                  {/* School */}
                  <li className="nav-item dropdown">
                     <a
                        className={`${(pathname?.includes(routeNames.general.school.lifeAtPises) || pathname?.includes(routeNames.general.school.achievements.examResults) || pathname?.includes(routeNames.general.school.achievements.otherAchievements) || pathname?.includes(routeNames.general.school.achievements.scienceAndTechnology) || pathname?.includes(routeNames.general.school.hygieneAndMedical) || pathname?.includes(routeNames.general.school.pisesFacilities) || pathname?.includes(routeNames.general.school.schoolTiming) || pathname?.includes(routeNames.general.school.schoolUniform)) ? 'active' : ''}`}
                     // href={routeNames.general.school.lifeAtPises}
                     >
                        <IoSchoolOutline className='i' />
                        {t("header.school")}
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className="project school">
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.school.lifeAtPises) ? 'active' : ''}`} href={routeNames.general.school.lifeAtPises}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.lifeAtPises")}
                           </a>
                        </li>
                        <li className="drop-item sub-dropdown">
                           <a
                           // className={`${(pathname?.includes(routeNames.general.school.achievements.examResults) || pathname?.includes(routeNames.general.school.achievements.otherAchievements) || pathname?.includes(routeNames.general.school.achievements.scienceAndTechnology || pathname?.includes(routeNames.general.school.schoolTiming))) ? 'active' : ''}`}
                           // href={routeNames.general.school.achievements.examResults}
                           >
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.achievements")}
                              <FiChevronRight className='arrow' />
                           </a>
                           <ul className="sub-project">
                              <li className="drop-item">
                                 <a className={`${pathname?.includes(routeNames.general.school.achievements.examResults) ? 'active' : ''}`} href={routeNames.general.school.achievements.examResults}>
                                    <BiCheckbox className='icon' />
                                    <BiCheckboxSquare className='icon-active' />
                                    {t("header.examResults")}
                                 </a>
                              </li>
                              <li className="drop-item">
                                 <a className={`${pathname?.includes(routeNames.general.school.achievements.scienceAndTechnology) ? 'active' : ''}`} href={routeNames.general.school.achievements.scienceAndTechnology}>
                                    <BiCheckbox className='icon' />
                                    <BiCheckboxSquare className='icon-active' />
                                    {t("header.scienceAndTechnology")}
                                 </a>
                              </li>
                              <li className="drop-item">
                                 <a className={`${pathname?.includes(routeNames.general.school.achievements.otherAchievements) ? 'active' : ''}`} href={routeNames.general.school.achievements.otherAchievements}>
                                    <BiCheckbox className='icon' />
                                    <BiCheckboxSquare className='icon-active' />
                                    {t("header.otherAchievements")}
                                 </a>
                              </li>
                           </ul>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.school.pisesFacilities) ? 'active' : ''}`} href={routeNames.general.school.pisesFacilities}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.pisesFacilities")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.school.hygieneAndMedical) ? 'active' : ''}`} href={routeNames.general.school.hygieneAndMedical}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.hygieneAndMedical")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.school.schoolTiming) ? 'active' : ''}`} href={routeNames.general.school.schoolTiming}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {"School Timing"}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.school.schoolUniform) ? 'active' : ''}`} href={routeNames.general.school.schoolUniform}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {"School Uniform"}
                           </a>
                        </li>
                     </ul>
                  </li>

                  {/* Admissions */}
                  <li className="nav-item dropdown">
                     <a
                        className={`${(pathname?.includes(routeNames.general.admissions.admissionNotice) || pathname?.includes(routeNames.general.admissions.admissionResult) || pathname?.includes(routeNames.general.admissions.feeStructure) ||
                           pathname?.includes(routeNames.general.admissions.entryTestGuidelines) || pathname?.includes(routeNames.general.admissions.pisesBankDetails || pathname?.includes(routeNames.general.admissions.admissionGuidelines))
                        ) ? 'active' : ''}`}
                     // href={routeNames.general.admissions.admissionNotice}
                     >
                        <AiOutlineUsergroupAdd className='i' />
                        {t("header.admissions")}
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className={`project admissions ${admissionResultExists ? 'showRes' : ''}`}>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.admissions.admissionNotice) ? 'active' : ''}`} href={routeNames.general.admissions.admissionNotice}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              Admission Notice
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.admissions.admissionGuidelines) ? 'active' : ''}`} href={routeNames.general.admissions.admissionGuidelines}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              Admission Procedure
                           </a>
                        </li>
                        {admissionResultExists &&
                           <li className="drop-item">
                              <a className={`${pathname?.includes(routeNames.general.admissions.admissionResult) ? 'active' : ''}`} href={routeNames.general.admissions.admissionResult}>
                                 <BiCheckbox className='icon' />
                                 <BiCheckboxSquare className='icon-active' />
                                 Admission Result
                              </a>
                           </li>
                        }
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.admissions.feeStructure) ? 'active' : ''}`} href={routeNames.general.admissions.feeStructure}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.feeStructure")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.admissions.entryTestGuidelines) ? 'active' : ''}`} href={routeNames.general.admissions.entryTestGuidelines}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.entryTestGuidelines")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.admissions.pisesBankDetails) ? 'active' : ''}`} href={routeNames.general.admissions.pisesBankDetails}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.pisesBankDetails")}
                           </a>
                        </li>
                     </ul>
                  </li>

                  {/* Academics */}
                  <li className="nav-item dropdown">
                     <a
                        className={`${(pathname?.includes(routeNames.general.academics.academicCalendar) || pathname?.includes(routeNames.general.academics.classesOffered) || pathname?.includes(routeNames.general.academics.preSchool) || pathname?.includes(routeNames.general.academics.juniorSchool) || pathname?.includes(routeNames.general.academics.middleSchool) || pathname?.includes(routeNames.general.academics.seniorSchool)) ? 'active' : ''}`}
                     // href={routeNames.general.academics.academicCalendar}
                     >
                        <TbAward className='i' />
                        {t("header.academics")}
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className="project academics">
                        <li className="drop-item sub-dropdown">
                           <a
                              className={`${pathname?.includes(routeNames.general.academics.academicCalendar) ? 'active' : ''}`}
                           // href={routeNames.general.academics.academicCalendar}
                           >
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.academicCalendar")}
                              <FiChevronRight className='arrow' />
                           </a>
                           <ul className="sub-project academics">
                              <li className="drop-item">
                                 <a className={`${pathname?.includes(routeNames.general.academics.academicCalendar) ? 'active' : ''}`} href={routeNames.general.academics.academicCalendar}>
                                    <BiCheckbox className='icon' />
                                    <BiCheckboxSquare className='icon-active' />
                                    {'Academics Calendar\n2024-2025'}
                                 </a>
                              </li>
                           </ul>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.academics.classesOffered) ? 'active' : ''}`} href={routeNames.general.academics.classesOffered}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.classesOffered")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.academics.preSchool) ? 'active' : ''}`} href={routeNames.general.academics.preSchool}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.preSchool")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.academics.juniorSchool) ? 'active' : ''}`} href={routeNames.general.academics.juniorSchool}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.juniorSchool")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.academics.middleSchool) ? 'active' : ''}`} href={routeNames.general.academics.middleSchool}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.middleSchool")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.academics.seniorSchool) ? 'active' : ''}`} href={routeNames.general.academics.seniorSchool}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.seniorSchool")}
                           </a>
                        </li>
                     </ul>
                  </li>

                  {/* Contact us */}
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.general.contactUs) ? 'active' : ''}`} href={routeNames.general.contactUs}>
                        <BiSupport className='i' />
                        {t("header.contactUs")}
                     </a>
                  </li>
               </ul>
               <div className='divider' />
               {/*  */}
               <ul className="nav-bar">
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.virtualLearning) ? 'active' : ''}`} href={routeNames.general.virtualLearning}>{t("header.virtualLearning")}</a>
                  </li>
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.cambridgeExamUpdates) ? 'active' : ''}`} href={routeNames.general.cambridgeExamUpdates}>{t("header.cambridgeExamUpdates")}</a>
                  </li>
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.jobOpportunities) ? 'active' : ''}`} href={routeNames.general.jobOpportunities}>{t("header.jobOpportunities")}</a>
                  </li>
                  {/* News & Events */}
                  <li className="nav-item dropdown">
                     <a
                        className={`${pathname?.includes(routeNames.general.newsEvents.newsArchive) ? 'active' : ''}`}
                     //  href={routeNames.general.newsEvents.newsArchive}
                     >
                        {t("header.newsEvents")}
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className="project newsEvents">
                        <li className="drop-item">
                           <a className={`${pathname?.includes(routeNames.general.newsEvents.newsArchive) ? 'active' : ''}`} href={routeNames.general.newsEvents.newsArchive}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.newsArchive")}
                           </a>
                        </li>
                     </ul>
                  </li>

                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.gallery) ? 'active' : ''}`} href={routeNames.general.gallery}>{"Gallery"}</a>
                  </li>
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.downloads) ? 'active' : ''}`} href={routeNames.general.downloads}>{t("header.downloads")}</a>
                  </li>
                  <li className="nav-item">
                     <a className={`nav-link ${pathname?.includes(routeNames.general.pisesianHangouts) ? 'active' : ''}`} href={routeNames.general.pisesianHangouts}>{t("header.pisesianHangouts")}</a>
                  </li>
                  {/* <li className="nav-item">
                     <a className="nav-link" target="_blank" rel="noreferrer" href={routeNames.general.pnsi}>{t("header.PSNI")}</a>
                  </li> */}

                  {/* Portal */}
                  <li className="nav-item dropdown">
                     <a href="#">
                        {t("header.portal")}
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className="project portal">
                        <li className="drop-item">
                           <a href={routeNames.general.login}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              Admin Portal
                           </a>
                        </li>
                        <li className="drop-item">
                           <a target="_blank" rel="noreferrer" href={routeNames.general.portal.staffPortal}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.staffPortal")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a target="_blank" rel="noreferrer" href={routeNames.general.portal.parentPortal}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.parentPortal")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a target="_blank" rel="noreferrer" href={routeNames.general.portal.studentPortal}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.studentPortal")}
                           </a>
                        </li>
                        <li className="drop-item">
                           <a target="_blank" rel="noreferrer" href={routeNames.general.portal.msTeams}>
                              <BiCheckbox className='icon' />
                              <BiCheckboxSquare className='icon-active' />
                              {t("header.msTeams")}
                           </a>
                        </li>
                     </ul>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   );
};

export default withTranslation()(Header);