/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import { getS3PresignedUrl } from '../../helpers/s3';

const DeferredImage = ({ src, alt, ...rest }) => {
   const imageRef = useRef(null);
   const isNotS3Img = src?.includes('/');

   useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
         entries.forEach(async (entry) => {
            if (entry.isIntersecting) {
               const img = imageRef.current;
               img.src = isNotS3Img ? src : await getS3PresignedUrl(src);
               observer.unobserve(img);
            }
         });
      });

      const img = imageRef.current;
      observer.observe(img);

      return () => {
         observer.unobserve(img);
      };
   }, [src]);

   return <img ref={imageRef} alt={alt} {...rest} />;
};

export default DeferredImage;
