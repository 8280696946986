import {
  S3Client,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const s3 = new S3Client({
  region: process.env.REACT_APP_AWS_S3_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY || "",
    secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY || "",
  },
});

export const getS3PresignedUrl = async (key) => {
  try {
    const command = new GetObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      Key: `uploads/${key}`,
    });
    const res = await getSignedUrl(s3, command, { expiresIn: 36000 });
    return res;
  } catch (error) {
    debugger
    console.log('img error:', error);
  }
};